import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
import { format } from 'date-fns';
import { mix } from '@theme-ui/color';
import Footer from '../components/footer';
import Socials from '../components/socials';
export const favTechs = ['Vue.js', 'NuxtJS', 'TailwindCSS', 'React.js', 'Gatsby', 'JavaScript', '.NET Core', 'C#', 'Python', 'HTML5', 'CSS3', 'C', 'Java', 'SQL Server', 'MySQL'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Message = makeShortcode("Message");
const Text = makeShortcode("Text");
const Divider = makeShortcode("Divider");
const Button = makeShortcode("Button");
const Link = makeShortcode("Link");
const Grid = makeShortcode("Grid");
const Box = makeShortcode("Box");
const Heading = makeShortcode("Heading");
const Badge = makeShortcode("Badge");
const SourceList = makeShortcode("SourceList");
const Flex = makeShortcode("Flex");
const Card = makeShortcode("Card");
const layoutProps = {
  favTechs,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`helloWorld`}</h1>
    <Message variant='success' mb={2} mdxType="Message">
  <Text variant='styles.h4' mdxType="Text">Hey Everyone, my name is Yash Dave!</Text>
  <Text mdxType="Text">I am a Software Engineer @ <a href="https://deepsource.io/" target="_blank" rel="noopener noreferrer">DeepSource</a>! Nowadays, I work with Vue.js and NuxtJS. But I remain familiar with React.js, Gatsby and Pure HTML+CSS+JS web apps.</Text>
  <Text mt={2} mdxType="Text">🤔 Find out what I have been upto recently, on my  <a href="https://timeline.amorpheuz.dev/" target="_blank" rel="noopener noreferrer">timeline</a>.</Text>
  <Divider mdxType="Divider" />
  <Button as='a' mr={2} href='documents/YashDave.pdf' variant='primary' role='button' download={`yash-dave-resume@${new Date().toDateString()}`} mdxType="Button">Resume📄</Button>
  <Link href='documents/YashDave.txt' role='button' mdxType="Link">.txt version</Link>
    </Message>
    <Message variant='success' mb={2} mdxType="Message">Dabbling into experimental cutting edge new technologies is my favorite pastime. I love motivating others to get into Open-Source! I am an occasional blogger.</Message>
    <Divider mdxType="Divider" />
    <Grid gap={2} columns={[1, '1fr 2fr']} mdxType="Grid">
  <Box mdxType="Box">
    <Heading variant='styles.h2' mdxType="Heading">getInTouch</Heading>
    <Socials mdxType="Socials" />
  </Box>
  <Box mdxType="Box">
    <Heading variant='styles.h2' mdxType="Heading">favoriteTech</Heading>
    {favTechs.map((favTech, index) => {
          return <Badge key={index} variant='primary' sx={{
            mr: 2,
            mb: 2,
            color: mix('secondary', 'success', `${index / favTechs.length}`),
            borderColor: mix('secondary', 'success', `${index / favTechs.length}`)
          }} mdxType="Badge">
            {favTech}
          </Badge>;
        })}
  </Box>
    </Grid>
    <Divider mdxType="Divider" />
    <Heading variant='styles.h2' mdxType="Heading">fetchProjects</Heading>
    <SourceList filter='projects' mdxType="SourceList">
  {projects => <Flex sx={{
        flexWrap: 'wrap',
        ml: theme => `-${theme.space[2]}px`,
        mr: theme => `-${theme.space[2]}px`
      }} mdxType="Flex">
      {projects.splice(0, 3).map((edge, index) => {
          const {
            frontmatter: {
              title,
              date,
              tags
            },
            fields: {
              slug
            }
          } = edge.node;
          return <Box key={index} sx={{
            mb: 3,
            maxWidth: ['100%', '50%', '33.333%'],
            width: ['100%', '50%', '33.333%']
          }} mdxType="Box">
            <Link href={slug} sx={{
              textDecoration: 'none'
            }} mdxType="Link">
              <Card sx={{
                ml: 2,
                mr: 2,
                p: 3
              }} mdxType="Card"> 
                <Text sx={{
                  fontSize: 0,
                  color: 'muted'
                }} mdxType="Text">{format(new Date(date), 'd-MMM-u')}</Text>
                <Heading variant='styles.h4' sx={{
                  color: 'text'
                }} mdxType="Heading">{title}</Heading>
                {tags.map((tag, index) => {
                  const {
                    name,
                    count,
                    percent
                  } = tag;
                  return <Badge key={index} variant='primary' sx={{
                    mr: 2,
                    mb: 2,
                    color: mix('success', 'secondary', `${index / tags.length}`),
                    borderColor: mix('success', 'secondary', `${index / tags.length}`)
                  }} mdxType="Badge">
                      {tag}
                    </Badge>;
                })}
                <Text sx={{
                  textDecoration: 'underline'
                }} mdxType="Text">View project</Text>
              </Card>
            </Link>
          </Box>;
        })}
    </Flex>}
    </SourceList>
    <Flex sx={{
      justifyContent: 'center'
    }} mdxType="Flex">
  <Button as='a' mr={2} href='/projects' variant='primary' mdxType="Button">Show me more!! 👨‍💻👩‍💻</Button>
    </Flex>
    <Divider mdxType="Divider" />
    <Heading variant='styles.h2' mdxType="Heading">fetchRecentPosts</Heading>
    <SourceList filter='posts' mdxType="SourceList">
  {posts => <Flex sx={{
        flexWrap: 'wrap',
        ml: theme => `-${theme.space[2]}px`,
        mr: theme => `-${theme.space[2]}px`
      }} mdxType="Flex">
      {posts.splice(0, 4).map((edge, index) => {
          const {
            frontmatter: {
              title,
              date,
              tags
            },
            fields: {
              slug
            }
          } = edge.node;
          return <Box key={index} sx={{
            mb: 3,
            maxWidth: ['100%', '50%'],
            width: ['100%', '50%']
          }} mdxType="Box">
            <Link href={slug} sx={{
              textDecoration: 'none'
            }} mdxType="Link">
              <Card sx={{
                ml: 2,
                mr: 2,
                p: 3
              }} mdxType="Card"> 
                <Text sx={{
                  fontSize: 0,
                  color: 'muted'
                }} mdxType="Text">{format(new Date(date), 'd-MMM-u')}</Text>
                <Heading variant='styles.h4' sx={{
                  color: 'text'
                }} mdxType="Heading">{title}</Heading>
                {tags.map((tag, index) => {
                  const {
                    name,
                    count,
                    percent
                  } = tag;
                  return <Badge key={index} variant='primary' sx={{
                    mr: 2,
                    mb: 2,
                    color: mix('success', 'secondary', `${index / tags.length}`),
                    borderColor: mix('success', 'secondary', `${index / tags.length}`)
                  }} mdxType="Badge">
                      {tag}
                    </Badge>;
                })}
                <Text sx={{
                  textDecoration: 'underline'
                }} mdxType="Text">View post</Text>
              </Card>
            </Link>
          </Box>;
        })}
    </Flex>}
    </SourceList>
    <Flex sx={{
      justifyContent: 'center'
    }} mdxType="Flex">
  <Button as='a' mr={2} href='/posts' variant='primary' mdxType="Button">Read more 📑</Button>
    </Flex>
    <Divider mdxType="Divider" />
    <Footer mdxType="Footer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      